.app {
  text-align: center;

  .project-button {
    width: 18%;
    height: 70px;
    align-self: flex-start;
    background-color: #d3543d;
    color: black;
    outline-color: black;
    border-radius: 50px;

    &:hover {
      background-color: #dd2b0c;
    }
  }

  .employees-title {
    color: white;
    justify-content: flex-start;
    display: flex;
    margin-left: 1em;
    margin-right: 0.5em;
  }
  .employees-title-container {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .charts-grid {
    .grid-rows {
      display: flex;
    }
  }

  .filter-grid {
    .kpi-title {
      color: white;
      margin-left: 1em;
    }

    .filters {
      display: flex;
      margin: 2em 1em;

      .filters-title {
        font-size: 1.2em;
        color: white;
      }

      .filters-date {
        display: flex;
        margin: 0 3%;

        justify-self: flex-end;
      }
    }

    .failedTimesheets {
      margin: 10vh 0 10vh 0;
      color: white;
    }
  }
}
