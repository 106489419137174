.app-header {
  .user-buttons {
    width: 25%;
    height: 100%;
    display: flex;

    .user-base-button {
      height: 100%;
      width: 60%;
      background-color: #d3543d;
      outline-color: black;
      color: black;
      border-radius: 1rem;
      margin: 4px;

      &:hover {
        background-color: #dd2b0c;
      }
      &:disabled {
        background-color: rgba(223, 130, 113, 0.87);
        color: #000000c6;
      }
    }
  }
}

.user-times {
  color: white;
  max-width: 1024px;
  margin: auto;

  .user-times-overview {
    display: flex;
    margin-bottom: 2em;

    .user-times-vacations {
      flex-grow: 1;
    }

    .user-times-work-life {
      flex-grow: 1;
    }
  }

  table {
    border-collapse: collapse;
    border-color: white;

    thead {
      tr {
        border-bottom: thin solid white;
      }
    }

    tbody {
      tr {
        border-bottom: thin solid white;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    th {
      text-align: unset;
      padding-right: 1em;

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .user-times-selected {
    display: flex;

    .user-times-selected-day {
      flex-grow: 1;
    }

    .user-times-selected-week {
      flex-grow: 1;
    }
  }
}

.calendar-range-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.yellow-text {
  .MuiPaper-root,
  .MuiButtonBase-root {
    color: #fcd838;
  }
}
