.chart-title-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .chart-title {
    font-weight: bold;
    font-size: 1.2em;
    color: white;
    margin-right: 0.5em;
  }
}

.chart {
  height: 600px;
  margin-bottom: 5rem;

  .error-message {
    font-size: 1em;
    color: white;
  }
  width: 100%;
}
