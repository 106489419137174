.users-users-list {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  row-gap: 2em;
  color: white;
  .user-avatar{
    height: 6em;
    width: 6em;
    margin:auto;
  }
  a {
    color: white;
    text-decoration: none;
  }

  .users-user-name {
    margin-top: 0.5em;
  }
}
