.error-message {
  color: white;
}

.project-chart-container {
  margin: 1em;

  .projects-title {
    color: white;
  }

  .project-date-filter {
    display: flex;
    justify-content: center;

    .project-date-picker {
      background-color: #393d41;
      padding: 0.25em;
      color: white;

      &:not(:last-of-type) {
        margin-right: 0.5em;
      }

      .MuiInputLabel-root {
        padding: 0.25em;
        color: white;
      }

      .MuiInputBase-root {
        color: white;
      }

      .MuiIconButton-root {
        color: white;
      }
    }
  }
}
