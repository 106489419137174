.app-header {
  .back-button {
    width: 10%;
    background-color: #d3543d;
    outline-color: black;
    color: black;
    border-radius: 50px;
    &:hover {
      background-color: #dd2b0c;
    }
  }

  .app-title {
    font-size: 1.5em;
  }
}
