.year-picker {
  width: 100px;
  .MuiOutlinedInput-input {
    background-color: #393d41;
    color: rgb(255, 255, 255);
  }

  .MuiSelect-root {
    color: rgb(255, 255, 255);
  }
  .picker-option {
    color: rgb(0, 0, 0);
  }
}
